<template>
  <el-row
    v-if="submitted"
    type="flex"
    justify="end"
    class="emoji-feedback"
    style="margin-top: 10px !important; margin-bottom: 0 !important"
  >
    <span style="font-size: 10px !important; word-break: break-word">
      {{ thanksLabel }}
      <el-button type="text" @click="submitted = false">
        <span v-show="isShowReRate" style="font-size: 10px !important">{{ reRateLabel }}</span>
      </el-button>
    </span>
  </el-row>
  <el-row
    v-else-if="!submitted"
    type="flex"
    justify="end"
    align="middle"
    style="margin-top: 10px !important; margin-bottom: 0 !important"
    class="emoji-feedback"
  >
    <el-col>
      <p
        v-show="isShowQuestionLabel && questionLabel"
        class="question-label"
        v-html="questionLabel"
      ></p>
    </el-col>
    <el-button
      v-for="(qr, i) in quckReplies"
      :key="`pop-${i}`"
      type="text"
      class="keyreply-bounce"
      v-text="qr.text"
      size="medium"
      style="padding: 2px !important; margin: 0 !important"
      @click="submitFeedback(qr)"
    />

    <!-- Input with comments -->
    <el-popover
      v-for="(qric, i) in quickRepliesInputWithComments"
      :key="`popinputcomment-${i}`"
      placement="bottom"
      trigger="manual"
      v-model="qric.visible"
    >
      <div
        :style="{
          fontFamily: settings.font,
          fontSize: '12px',
          marginBottom: '5px',
        }"
      >
        {{ helpUsImproveBetter }}
      </div>
      <textarea
        v-model="qric.data.comments"
        maxlength="140"
        :style="{
          fontFamily: settings.font,
          height: '20px',
          width: '90%',
        }"
      ></textarea>
      <div style="text-align: right; margin: 0">
        <el-button type="primary" size="mini" @click="submitFeedback(qric)">
          {{ qric.data.comments ? submitButton : submitWithoutFeedback }}
        </el-button>
      </div>
      <el-button
        class="keyreply-bounce"
        slot="reference"
        type="text"
        v-text="qric.text"
        size="medium"
        style="padding: 2px !important; margin: 0 !important"
        @click="togglePopoverInput(i)"
      />
    </el-popover>

    <!-- Comments -->
    <el-popover
      v-if="quickRepliesComments.length"
      placement="bottom"
      trigger="manual"
      v-model="quickRepliesComments[0].visible"
    >
      <div
        :style="{
          fontFamily: settings.font,
          fontSize: '12px',
          marginBottom: '5px',
        }"
      >
        {{ inputYourFeedbackLabel }}
      </div>
      <textarea
        v-model="quickRepliesComments[0].data.value"
        maxlength="140"
        :style="{
          fontFamily: settings.font,
        }"
      ></textarea>
      <div style="text-align: right; margin: 0">
        <el-button type="primary" size="mini" @click="submitFeedback(quickRepliesComments[0])">
          {{ submitButton }}
        </el-button>
      </div>
      <el-button
        class="keyreply-bounce"
        slot="reference"
        type="text"
        v-text="quickRepliesComments[0].text"
        size="medium"
        style="padding: 2px !important; margin: 0 !important"
        @click="togglePopoverComment()"
      />
    </el-popover>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash-es";
import { mixin } from "@/mixins/feedbacks_mixin";
export default {
  mixins: [mixin],
  props: ["message"],
  data() {
    return {
      quckReplies: [],
      quickRepliesComments: [
        {
          visible: false,
          data: {
            value: "",
          },
        },
      ],
      quickRepliesInputWithComments: [
        {
          visible: false,
          data: {
            comments: "",
          },
        },
      ],
      submitted: false,
    };
  },
  mounted() {
    this.quckReplies = _.cloneDeep(this.message.data.learningQuickReplies);

    // comments
    const quickRepliesComments = _.remove(this.quckReplies, (qr) =>
      _.get(qr, "data.type", ["input"]).includes("comment")
    ).map((qr) => ({
      ...qr,
      visible: false,
    }));
    if (quickRepliesComments.length) {
      this.quickRepliesComments = quickRepliesComments;
    }

    // input with comment
    const quickRepliesInputWithComments = _.remove(quickRepliesComments, (qr) =>
      _.get(qr, "data.type", ["input"]).includes("input")
    ).map((qr) => ({
      ...qr,
      data: {
        ...qr.data,
        comments: "",
      },
      visible: false,
    }));
    if (quickRepliesInputWithComments.length) {
      this.quickRepliesInputWithComments = quickRepliesInputWithComments;
    }
  },
  computed: {
    ...mapGetters({ messages: "messages" }),
    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },
    lastMessageWithFeedback() {
      const sorted = _.orderBy(this.messages, ["timestamp"], ["asc"]);
      const last = _.findLast(sorted, (msg) => msg.data.learningQuickReplies?.length);
      return last.id;
    },
    isShowQuestionLabel() {
      const label = _.get(this, "settings.feedbackCollectionShowLabel", false);
      return label;
    },
    isShowReRate() {
      const showReRate = !_.get(this, "settings.feedbackCollectionDisableReRate", false);
      return showReRate;
    },
    questionLabel() {
      return this.getLabelFeedbacks(
        "customLearningQuestionLabel",
        "Did this answer your question?"
      );
    },
    thanksLabel() {
      return this.getLabelFeedbacks("customLearningThanksLabel", "🙏 Thanks for your feedback");
    },
    helpUsImproveBetter() {
      return this.getLabelFeedbacks("helpUsImproveBetterLabel", "Help us improve better");
    },
    submitWithoutFeedback() {
      return this.getLabelFeedbacks("submitWithoutFeedbackLabel", "Submit without feedback");
    },
    submitButton() {
      return this.getLabelFeedbacks("submitLabel", "Submit");
    },
    reRateLabel() {
      return this.getLabelFeedbacks("reRateLabel", "Re-rate");
    },
    inputYourFeedbackLabel() {
      return this.getLabelFeedbacks("inputYourFeedbackLabel", "Input your feedback");
    },
  },
  methods: {
    togglePopoverComment() {
      if (!this.quickRepliesComments[0].visible) {
        this.quickRepliesInputWithComments = this.quickRepliesInputWithComments.map((qr) => ({
          ...qr,
          visible: false,
        }));
      }
      this.quickRepliesComments[0].visible = !this.quickRepliesComments[0].visible;
    },
    togglePopoverInput(i) {
      if (!this.quickRepliesInputWithComments[i].visible) {
        this.handleQuickRepliesCommentVisibility();
        this.quickRepliesInputWithComments = this.quickRepliesInputWithComments.map((qr, idx) => {
          return idx === i
            ? qr
            : {
                ...qr,
                visible: false,
              };
        });
      }
      this.quickRepliesInputWithComments[i].visible =
        !this.quickRepliesInputWithComments[i].visible;
    },
    getNotifyBoxOffset() {
      return this.isMobileMode ? 40 : 600;
    },
    handleQuickRepliesCommentVisibility() {
      if (this.quickRepliesComments.length) {
        this.quickRepliesComments[0].visible = false;
      }
    },
    submitFeedback(feedback) {
      const value = feedback.data.value;
      if (!value) {
        return;
      }

      const options = feedback.options || {};
      const queryType = feedback.options?.queryType || "faq";
      const mutatedButton = {
        ...feedback,
        data: {
          value,
          options: {
            ...options,
            feedbackCollection: true,
            queryType,
            // if there is no comment will be undefined
            comments: feedback.data.comments,
          },
        },
        // omit the visible state to sent
        visible: undefined,
      };
      this.$emit("sendPostback", mutatedButton);
      this.submitted = true;
      this.handleQuickRepliesCommentVisibility();
      this.quickRepliesInputWithComments = this.quickRepliesInputWithComments.map((qr) => ({
        ...qr,
        visible: false,
      }));
    },
  },
};
</script>

<style scoped lang="scss">
.keyreply-bounce {
  animation-duration: 1s;
  transform-origin: bottom;
}

.keyreply-bounce:hover {
  animation-name: keyreply-bounce;
  animation-timing-function: ease;
}

textarea {
  padding: 5px;
  border: 3px solid #cccccc;
  margin-bottom: 10px;
  font-size: 12px;
}

.question-label {
  margin-right: 5px;
  float: right;
  font-size: 10px !important;
  word-break: break-word;
}

@keyframes keyreply-bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>

<style>
.emoji-feedback button {
  min-width: auto;
}
</style>
