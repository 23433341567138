<template>
  <div class="bubble-wrapper" v-if="text">
    <div class="message-wrapper message-bubble-left" :style="bubbleStyle">
      <p style="margin-bottom: 0 !important; word-break: break-word" v-html="markedText"></p>
    </div>
    <div class="message-slider text-right">
      <div class="slider-text">
        <span>{{ sliderConfig.minText }}</span>
        <span :style="{ color: sliderConfig.mainColor }">{{ sliderConfig.maxText }}</span>
      </div>
      <div style="position: relative">
        <div class="slider-group" ref="sliderGroup">
          <div
            class="slider-item"
            v-for="(unit, index) in sliderConfig.units"
            :key="index"
            :style="sliderItemStyle(index)"
          ></div>
        </div>
        <div
          slot="reference"
          class="slider-dot"
          ref="sliderDot"
          :style="{ backgroundColor: sliderConfig.mainColor }"
          @mousedown.prevent="isSliderDraggable = true"
          @touchstart="isSliderDraggable = true"
        >
          {{ selectedUnit }}
        </div>
      </div>
      <el-button
        plain
        size="mini"
        class="confirm-button"
        :type="buttonOutline"
        :class="{ 'blue-button': buttonOutline === 'primary' }"
        @click="handleSliderConfirm"
      >
        Confirm
      </el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash-es";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  props: ["message", "buttonOutline", "isUserMessage", "agents", "shouldDisableIfInLivechat"],
  data() {
    return {
      isResendButtonDisabled: false,
      isMessageSent: true,
      isSliderDraggable: false,
      selectedUnit: 1,
      visible: false,
    };
  },
  methods: {
    /**
     * @description Get timestamp
     * @param message
     * @return {string}
     */
    timestamp(message) {
      const source = this.isUserMessage ? "You" : this.replyName;
      return `${source} at ${dayjs(message.timestamp).format("D MMM, hh:mma")}`;
    },
    updateMessageStatus(status) {
      this.$set(this, "isMessageSent", status || status === undefined);
      this.$set(this, "isResendButtonDisabled", false);
    },
    /**
     * @description Check if string only contains whitespace
     * @param string
     * @return {boolean}
     */
    isOnlyWhiteSpace(str) {
      return !str.replace(/\s/g, "").length;
    },
    sliderItemStyle(index) {
      return {
        backgroundColor: this.sliderConfig.mainColor,
        opacity: (100 / this.sliderConfig.units) * (index + 1) + "%",
        flexBasis: 100 / this.sliderConfig.units + "%",
      };
    },
    handleSliderChange(e) {
      e.preventDefault;
      const sliderEl = this.$refs.sliderGroup;
      const sliderDotEl = this.$refs.sliderDot;
      const eventPosition = e.type === "touchmove" ? e.touches[0].clientX : e.clientX;
      if (this.isSliderDraggable) {
        const sliderElDOMRect = sliderEl.getBoundingClientRect();
        const sliderDotPosition = eventPosition - sliderElDOMRect.x;
        if (sliderDotPosition < sliderElDOMRect.width && sliderDotPosition > 0) {
          let percent = (sliderDotPosition / sliderElDOMRect.width) * 100;
          if (this.isRtlModeEnabled) {
            percent = 100 - percent;
            sliderDotEl.style.right = percent + "%";
          } else {
            sliderDotEl.style.left = percent + "%";
          }
          this.selectedUnit = Math.ceil(percent / (100 / this.sliderConfig.units));
        }
      }
    },
    handleSliderConfirm() {
      const { key, next, event } = this.nodeEvent;
      const reply = {
        active: true,
        data: {
          key,
          next,
          value: this.selectedUnit,
        },
        event,
        text: this.nodeEvent.text || "Confirm rating",
      };
      this.$emit("sendPostback", reply);
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapGetters(["isRtlModeEnabled"]),
    settings() {
      return this.$store.getters.settings;
    },
    bubbleStyle() {
      const background = _.isEmpty(this.settings.background)
        ? "white"
        : `url('${this.settings.background}')`;
      const style = {
        "--style-bubble-color": this.settings.agentBubbleColor,
        "--background": background,
        fontFamily: this.settings.font,
        color: `${this.settings.agentTextColor} !important`,
        background: this.settings.agentBubbleColor,
        borderColor: this.settings.agentBubbleColor + " transparent transparent transparent",
      };
      return style;
    },

    /**
     * @description Message content text getter
     * @return {string}
     */
    text() {
      return (
        _.get(this.message, "data.content[0].text", null) ||
        _.get(this.message, "data.content[0].postbackText", null)
      );
    },

    /**
     * @description Sanitize html content
     * @return {string}
     */
    markedText() {
      return this.$options.filters.markHTML(this.text, this.userTextColor);
    },
    sliderConfig() {
      return _.get(this.message, "data.content[0].sliderConfig", null);
    },
    nodeEvent() {
      return this.message.data.content[0].sliderConfig.nodeEvent;
    },
  },
  watch: {
    message: function (newVal, oldVal) {
      this.updateMessageStatus(newVal.sent);
    },
  },
  mounted() {
    document.addEventListener("mousemove", this.handleSliderChange);
    document.addEventListener("touchmove", this.handleSliderChange);
    document.addEventListener("mouseup", () => {
      this.isSliderDraggable = false;
    });
    document.addEventListener("touchend", () => {
      this.isSliderDraggable = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
  display: inline-block;
  *:not(button) {
    font-size: 14px !important;
  }

  p {
    margin-top: 0;
    margin-bottom: 16px !important;
    line-height: 1.2;
    padding-bottom: 0;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  ul,
  ol {
    padding: revert;
  }

  li {
    margin-top: 0;
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline !important;
  }

  a,
  a:visited,
  p,
  li {
    color: inherit;
  }

  img {
    border-radius: 15px;
  }
}

[dir="ltr"] .message-wrapper {
  li {
    margin-left: 2px;
  }
}

[dir="rtl"] .message-wrapper {
  li {
    margin-right: 2px;
  }
}

.message-bubble-status {
  font-size: 14px;
  position: absolute;
  bottom: 3px;
  z-index: 10;
  font-weight: bold;
}

[dir="ltr"] .message-bubble-status {
  right: 2px;
}

[dir="rtl"] .message-bubble-status {
  left: 2px;
}

.message-bubble-left {
  position: relative;
  padding: 16px;
  background: #eee;
  color: #333;
  max-width: 70%;
  margin: 0;
}

[dir="ltr"] .message-bubble-left {
  border-radius: 16px 16px 16px 0;
  text-align: left;
}

[dir="rtl"] .message-bubble-left {
  border-radius: 16px 16px 0 16px;
  text-align: right;
}

.bubble-wrapper {
  display: flex;
  flex-direction: column;
  margin: 8px 16px;
  align-items: flex-start;
}

.message-slider {
  width: 70%;

  .slider-text {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
  }

  .slider-group {
    display: flex;
    border-radius: 50px;
    overflow: hidden;
    cursor: grab;

    .slider-item {
      height: 8px;
      background: black;
    }
  }
  .slider-dot {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 200px;
    font-size: 14px;
    color: #fff;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
  .confirm-button {
    margin: 12px 0;
    border-radius: 8px;
    font-size: 14px;

    &.blue-button {
      color: #4e6cce;
      background: #dce2f5;
      border-color: #4e6cce;

      &:hover {
        background: #4e6cce;
        color: #fff;
      }
    }

    &.active {
      background: transparent;
    }
  }
}

[dir="ltr"] .message-slider {
  .slider-dot {
    left: 10%;
    transform: translate(-50%, -50%);
  }
  .confirm-icon {
    text-align: right;
  }
}

[dir="rtl"] .message-slider {
  .slider-dot {
    right: 10%;
    transform: translate(50%, -50%);
  }
  .confirm-icon {
    text-align: left;
  }
}
</style>

<style>
.message-bubble-left .el-select__input {
  background: transparent !important;
  border: none !important;
}
</style>
