// polyfill all `core-js` features, including early-stage proposals:
import "core-js";
import Vue from "vue";
import * as filters from "@/filters"; // Custom filters

import {
  Checkbox,
  Dialog,
  Divider,
  Drawer,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Select,
  Option,
  Icon,
  Row,
  Rate,
  Col,
  Upload,
  Card,
  Container,
  Header,
  Main,
  Footer,
  Loading,
  MessageBox,
  Message,
  Notification,
  Popover,
} from "element-ui";
//@ts-ignore
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
Vue.component(CollapseTransition.name, CollapseTransition);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Input);
Vue.use(Button);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Select);
Vue.use(Option);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Rate);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Card);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Popover);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
import "element-ui/lib/theme-chalk/index.css";
import _ from "lodash-es";
import KeyReply from "./App.vue";
import store from "./store";
// @ts-ignore
import Vue2TouchEvents from "vue2-touch-events";
import speech from "./speech";

Vue.config.productionTip = false;

store.dispatch("RELOAD", {
  title: "",

  // Launcher icon
  botIcon: null,
  logo: null,

  // Style
  background: "#FFF",
  color: "#e4392b",
  headerColor: "#e4392b",
  headerBorderColor: "#e4392b",
  agentBubbleColor: "#F24C63",
  agentTextColor: "#FFF",
  userBubbleColor: "#000",
  userTextColor: "#FFF",
  trendingTopicBanner: "#808080",
  trendingTopicTitle: "#000000",
  widgetIconTextColor: "#FFF",
  widgetIconColor: "#4e6cce",
  headerModalIconColor: "",
  userMainBackgroundColor: "",
  userMainTitleColor: "#4e6cce",

  font: "",
  position: "right",
  size: "large",

  // Behaviours
  popup: false,
  resume: false,
  suggestions: [],
  exclude: "",
  ga: "",
  showSuggestions: false,
  charLimit: {
    showCountDown: false,
    limit: 1000,
    countdownType: "increase",
  },
  showPreChatForm: { enabled: false },
  showPostChatForm: { enabled: false },
  openAutomatically: false,
  fileUpload: false,
  onlyAllowUploadWhenInLiveChat: true,
  displayLauncherOnAgentAvailability: { delay: false, number: 0 },
  disableTextInput: false,

  alwaysShowQuickReply: true,
  stickyMenuConfig: {
    stickyMenuLanguages: [
      {
        lang: "en",
        value: "Go to menu",
      },
    ],
  },
  videoVAConfig: {
    subtitleFontSize: 16,
    enableAlphabetButton: true,
  },
  stickyMenu: null,
  stickyMenuLanguage: "en",
  // Data
  apps: "e30=",
  message: "bWVzc2FnZQ==",
  appSource: "",
  genai_enabled: false,
});

// Register global utility filters.
_.forEach(filters, (definition, id) => {
  Vue.filter(id, definition);
});

Vue.use({
  install(V) {
    const v = V;
    const ua = navigator.userAgent;
    const params = new URLSearchParams(location.search);
    const isMobileQuery = params.get("mode") === "mobile";
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const iOSSDK = !!ua.match(/KeyReplyiOSSDK/i);
    const Android = !!ua.match(/Android/i);
    const Mobile = !!ua.match(/Mobi/i);
    const Mac = !!ua.match(/Macintosh/i);
    const isIEOrEdge = () => {
      if (!navigator) return false;
      let _ieOrEdge = false;
      if (/MSIE 10/i.test(navigator.userAgent)) {
        // This is internet explorer 10
        _ieOrEdge = true;
      }
      if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
        // This is internet explorer 9 or 11
        _ieOrEdge = true;
      }
      if (/Edge\/\d./i.test(navigator.userAgent)) {
        // This is Microsoft Edge
        _ieOrEdge = true;
      }
      return _ieOrEdge;
    };

    v.prototype.$device = {
      iOS,
      iOSSDK,
      Android,
      Mobile,
      Mac,
      isMobileQuery,
      ie: isIEOrEdge(),
    };
  },
});

Vue.use(speech);

window.$keyreply = {
  actions: [
    "START",
    "LOGIN",
    "FETCH_ENDPOINT",
    "OPEN_CHAT_WINDOW",
    "CLOSE_CHAT_WINDOW",
    "TOGGLE_CHAT_WINDOW",
    "SEND_MESSAGE",
    "SEND_POSTBACK",
    "END_LIVE_CHAT",
    "CLOSE_WEBVIEW",
    "INITIALIZE",
    "SET_JWT_RESEND_REQUEST",
    "SET_EMAIL_WHITELIST",
    "GET_LIVECHAT_STATUS",
    "SET_NATIVE_ACTION",
    "SET_CUSTOM_SUGGESTIONS",
    "SET_STICKY_MENU_LANGUAGE",
    // "SET_WEBVIEWURL" // Disabled for security consideration
  ],

  async dispatch(event, payload) {
    if (window.$keyreply.actions.indexOf(event) > -1) {
      return store.dispatch(event, payload);
    }
  },
};

const el = document.createElement("div");
el.setAttribute("id", "app");
document.body.appendChild(el);
Vue.use(Vue2TouchEvents);

//work-around for typing error
const vueUtil = Vue.util as any;
new Vue(
  vueUtil.extend(
    {
      el: "#app",
      store,
    },
    KeyReply
  )
);
