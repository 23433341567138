import { MutationTree } from "vuex";
import _ from "lodash-es";
import { RootState, Message } from "./types";
import Vue from "vue";
import { santizeText, splitQuickReplies } from "@/helpers/sanitizer";
import addUnreadMessageBadge from "@/helpers/addUnreadMessageBadge";
import setDocumentTitle from "@/helpers/setDocumentTitle";
import playNotificationSound from "@/helpers/playNotificationSound";
import pushNotification from "@/helpers/pushNotification";
import initiateFuseInstance from "@/helpers/initiateFuseInstance";
import getParamsFromUrl from "../helpers/getParamsFromUrl";
import { MessageBox } from "element-ui";

export const mutations: MutationTree<RootState> = {
  JWT_MESSAGE(state, { title, text, iconType = "warning", cancel = false }) {
    const jwtDrawer = {
      ...state.jwtDrawer,
      enabled: true,
      title,
      text,
      iconType,
      cancel,
    };
    Vue.set(state, "jwtDrawer", jwtDrawer);
  },
  DISABLE_TEXT_INPUT(state, status) {
    Vue.set(state, "disableTextInput", status);
  },
  SET_TRENDING_TOPICS(state, payload) {
    Vue.set(state, "trendingTopics", payload);
  },
  SET_CURRENT_LANGUAGE(state, payload) {
    Vue.set(state, "currentLanguage", payload);
  },
  UPDATE_SHOW_PREVIEW_BUBBLE(state, { status }) {
    Vue.set(state, "showMessagePreview", status);
  },
  RECEIVE_ALL(state, { messages }: { messages: Message[] }) {
    messages.forEach((message) => {
      state.messages.push(message);
    });
  },
  SET_EMAIL_WHITELIST(state, payload) {
    Vue.set(state, "emailDomainWhitelist", payload);
  },
  SET_CUSTOM_SUGGESTIONS(state, payload) {
    state.fuse = initiateFuseInstance(payload);
  },
  SET_WEBVIEWURL(state, url: string) {
    Vue.set(state, "webviewUrl", url);
  },
  SET_SHOW_WEBVIEW(state, payload: boolean) {
    Vue.set(state, "showWebview", payload);
  },
  SET_RETURN_TO_CHAT(state, returnToChatButton: boolean) {
    Vue.set(state, "returnToChatButton", returnToChatButton);
  },
  RECEIVE_MESSAGE(state, message) {
    const params = getParamsFromUrl();
    const mobileMode = params.get("mode") === "mobile";

    if (!_.find(state.messages, { id: message.id })) {
      if (state.settings?.feedbackCollectionEnabled) {
        const [definedQuickReplies, learningQuickReplies] = splitQuickReplies(
          message.data.quickReplies
        );
        message.data.quickReplies = definedQuickReplies;
        message.data.learningQuickReplies = learningQuickReplies;
      }
      state.messages.push(message);
      if (
        state.settings.enablePushNotification &&
        document.hidden &&
        state.settings.liveChatStatus &&
        !mobileMode
      ) {
        state.unreadChatsCount += 1;
        setDocumentTitle(state.documentTitle, state.unreadChatsCount);
        pushNotification(
          "New message",
          message.data.content[0].text,
          state.settings.pushNotificationImageURL,
          () => {
            window.focus();
          }
        );

        const ua = navigator.userAgent;
        const isMobileQuery = params.get("mode") === "mobile";
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const iOSSDK = !!ua.match(/KeyReplyiOSSDK/i);
        const Android = !!ua.match(/Android/i);
        const Mobile = !!ua.match(/Mobi/i);
        const mobileEnvironment = isMobileQuery || iOS || iOSSDK || Android || Mobile;
        if (!mobileEnvironment) {
          playNotificationSound();
        }
      }
      if (!state.chatWindow) {
        addUnreadMessageBadge(state.unreadChatsCount);
      }
    }
  },
  SYNC_MESSAGE_TIMESTAMP(state, { messageIndex, serverTimestamp }) {
    state.messages[messageIndex].timestamp = serverTimestamp;
  },
  UPDATE_SOCKET_CONNECTION_STATUS(state, flag) {
    state.socketConnectionStatus = flag;
  },
  UPDATE_INITIALIZING_DONE(state) {
    state.isInitiating = false;
  },
  SEND_FILE(state, { content, id }) {
    const last = _.last(_.orderBy(state.messages, ["timestamp"], ["asc"]));
    let timestamp = new Date();

    if (last && last.timestamp && new Date(last.timestamp).getTime() > timestamp.getTime()) {
      // Time is messed up!
      // Set this message's time to 100 millisecond after the last message
      timestamp = new Date(new Date(last.timestamp).getTime() + 1000);
    }

    const contentType = content.images ? "images" : "files";
    const contentObj: any = {};
    contentObj[contentType] = content[contentType];
    const message: Message = {
      id,
      timestamp,
      type: "message",
      data: {
        content: [contentObj],
      },
    };

    if (!_.find(state.messages, { id })) {
      state.messages.push(message);
    }
  },
  SEND_MESSAGE(state, { type = "message", text, id, trendingText, timestamp = new Date() }) {
    const last = _.last(_.orderBy(state.messages, ["timestamp"], ["asc"]));

    if (last && last.timestamp && new Date(last.timestamp).getTime() > timestamp.getTime()) {
      // Time is messed up!
      // Set this message's time to 100 millisecond after the last message
      timestamp = new Date(new Date(last.timestamp).getTime() + 1000);
    }

    const sanitisedText = santizeText(text);
    const message: Message = {
      id,
      timestamp,
      type,
      data: {
        content: [
          {
            text: sanitisedText,
          },
        ],
      },
      trendingText,
    };

    if (!_.find(state.messages, { id })) {
      state.messages.push(message);
    }
  },
  UPDATE_MESSAGE_STATUS(state, { id, sent, errorMessage }) {
    state.messages = _.map(state.messages, (message) => {
      if (id === message.id) {
        return {
          ...message,
          sent: sent,
          errorMessage,
        };
      }
      return message;
    });
  },
  CLEAR_MESSAGES(state) {
    state.messages = [];
  },
  SET_ALL_PAST_MESSAGES_FETCHED(state) {
    state.allPastMessagesFetched = true;
  },
  SET_NEW_USER(state, isNewUser = true) {
    state.newUser = isNewUser;
  },
  SET_EXISTING_USER(state) {
    state.newUser = false;
  },
  SET_SIMULATE_USER(state, isSimulatedUser) {
    state.simulateUser = isSimulatedUser;
  },
  SET_SESSION_EXPIRED(state, status) {
    state.sessionExpired = status;
  },
  SET_SESSION_EXPIRY_TIMEOUT(state, timer) {
    state.sessionExpiryTimeout = timer;
  },
  SET_USER_ID(state, id) {
    state.uid = id;
  },
  SET_PAGE_ID(state, id) {
    state.page_id = id;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_DISPLAY_LAUNCHER(state, status: boolean) {
    Vue.set(state, "displayLauncher", status);
  },
  SET_COLOR_TITLE_TRENING(state, colorTitle) {
    state.settings.trendingTopicTitle = colorTitle;
  },
  SET_COLOR_BANNER_TRENING(state, colorBanner) {
    state.settings.trendingTopicBanner = colorBanner;
  },
  MERGE_SETTINGS(state, settings) {
    if (_.isEmpty(settings)) {
      return;
    }

    if (state.settings) {
      const newSettings = Object.assign({}, state.settings, settings);
      Vue.set(state, "settings", newSettings);
    } else {
      Vue.set(state, "settings", settings);
    }

    if (state.settings.botIcon && settings.botIcon) {
      Vue.set(state, "settings.botIcon", settings.botIcon);
    }

    if (state.settings.suggestions) {
      state.fuse = initiateFuseInstance(state.settings.suggestions);
    }
  },
  UPDATE_ONLINE_STATUS(state) {
    state.online = navigator.onLine;
  },
  OPEN_CHAT_WINDOW(state) {
    window.top?.postMessage("widgetopened", "*");
    state.chatWindow = true;
  },
  CLOSE_CHAT_WINDOW(state) {
    window.top?.postMessage("widgetclosed", "*");
    state.chatWindow = false;
  },
  OPEN_FACEBOOK_CHAT_WINDOW(state) {
    state.facebookChatWindow = true;
    window.FB.CustomerChat.showDialog();
  },
  CLOSE_FACEBOOK_CHAT_WINDOW(state) {
    state.facebookChatWindow = false;
    window.FB.CustomerChat.hideDialog();
  },
  SHOW_TEXTBAR(state) {
    localStorage.setItem("showTextbar", "true");
    state.settings.showTextbar = true;
  },
  HIDE_TEXTBAR(state) {
    localStorage.removeItem("showTextbar");
    state.settings.showTextbar = false;
  },
  TOGGLE_LIVECHAT_BANNER(state, status) {
    if (
      state.settings.liveChatStatus &&
      !status &&
      state.settings.disableTextInputAfterLivechatSession
    ) {
      state.disableTextInput = true;
    }
    state.settings.liveChatStatus = status;
  },
  SET_ECE_ACTIVITY_ID(state, eceActivityId) {
    state.settings.eceActivityId = eceActivityId;
  },
  TOGGLE_TYPING_INDICATOR(state, status) {
    state.settings.typingIndicator.status = status;
  },
  OPEN_DIALOG(state) {
    state.lightbox = true;
  },
  CLOSE_DIALOG(state) {
    state.lightbox = false;
  },
  SET_GREETED(state, greeted) {
    state.greeted = greeted;
  },

  /**
   * @description Mutation on state.isSendingPostback variable
   * @param state
   * @param {boolean} isBusy
   * @return {void}
   */
  SET_SENDING_POSTBACK(state, isBusy: boolean) {
    Vue.set(state, "isSendingPostback", isBusy);
  },
  SET_WEBCHAT_USERS_IS_AT_MAX_LIMIT(state, isMax: boolean) {
    Vue.set(state, "webchatUsersIsAtMaxLimit", isMax);
  },
  CLEAR_UNREAD_MESSAGE_COUNT(state) {
    state.unreadChatsCount = 0;
    setDocumentTitle(state.documentTitle);
    addUnreadMessageBadge(state.unreadChatsCount);
  },
  SET_NOTIFICATION_PERMISSION(state, isEnabledNotification: boolean) {
    Vue.set(state, "isEnabledNotification", isEnabledNotification);
  },
  SET_END_SESSION(state, sessionEnded: boolean) {
    Vue.set(state, "sessionEnded", sessionEnded);
  },
  SET_SESSION_BUTTON_READY(state) {
    Vue.set(state, "sessionButtonReady", true);
  },
  SET_NATIVE_ACTION(state, fn) {
    state.jwtDrawer!.action = fn;
  },
  SET_COBROWSE_SESSION(state, status: boolean) {
    const cobrowse = window.CobrowseIO;
    const enabled = state.settings.cobrowse?.enabled || false;
    const key = state.settings.cobrowse?.apiKey || "";
    const promptTitle =
      state.settings.cobrowse?.promptTitle || "Allow support agent to use this web page with you?";
    const promptMessage = state.settings.cobrowse?.promptMessage || "Support Request";
    if (cobrowse && !!(enabled && key)) {
      cobrowse.license = key;
      // user data
      cobrowse.customData = {
        user_id: state.uid,
      };
      cobrowse.confirmSession = () => {
        return MessageBox.confirm(promptTitle, promptMessage, {
          confirmButtonText: "Allow",
          cancelButtonText: "Deny",
          type: "info",
        });
      };
      if (status) {
        cobrowse.client().then((c: any) => {
          c.start({ allowIFrameStart: true });
        });
      } else {
        cobrowse.client().then((c: any) => {
          c.stop();
        });
      }
    }
  },
  SET_STICKY_MENU_LANGUAGE(state, language) {
    Vue.set(state.settings, "stickyMenuLanguage", language);
  },
  SET_LIVECHAT_AGENT_NAME(state, agentName) {
    Vue.set(state, "liveChatAgentName", agentName);
  },
  SET_SIGNALR_CONNECTION(state, connection) {
    Vue.set(state, "signalRConnectionId", connection);
  },
};
